import { AnalyticsBrowser } from '@segment/analytics-next';
import { ServerTypes, SearchLabel } from 'lib/types';
import {
  defaultRecommendedDev,
  defaultRecommendedProd,
} from 'components/recommendedQs/defaultRecommended';
import { SearchCompanion } from 'providers/searchCompanion';
import { SourceTitleWithState } from 'providers/question';
import UAParser from 'ua-parser-js';

function isDefaultQuery(
  queryId: string | undefined,
  currRoute: string,
): boolean {
  if (!queryId) return false;
  const defaultRecommendedQs =
    currRoute.substring(0, 4) == 'dev'
      ? defaultRecommendedDev
      : defaultRecommendedProd;
  const defaultQueryIds = defaultRecommendedQs
    .map((tq) => tq.questions.map((q) => q.searchId))
    .flat();
  return defaultQueryIds.includes(queryId);
}

export class Analytics {
  private segment: AnalyticsBrowser;

  constructor(writeKey: string) {
    this.segment = AnalyticsBrowser.load({ writeKey });
  }

  private getMeta() {
    const parser = new UAParser();
    const { device, os, browser } = parser.getResult();
    return {
      os: os.name,
      version: os.version,
      device: device.model,
      browser: browser.name,
    };
  }

  public getCleanUserData(
    user: Partial<ServerTypes.UserWithProfessions> | null,
  ) {
    if (!user) return { ...this.getMeta() };
    const name = `${user?.firstName} ${user?.lastName}`;
    return {
      email: user?.userName,
      name,
      userId: user?.id,
      sub: user?.authUserId,
      createdAt: user?.createdAt,
      profession: user?.professions?.[0]?.name,
      ...this.getMeta(),
    };
  }

  public identify(user: Partial<ServerTypes.UserWithProfessions> | null): void {
    this.segment.identify(user?.id?.toString(), this.getCleanUserData(user), {
      Mixpanel: true,
    });
  }

  public track(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    eventName: string,
    properties: Record<string, any>,
  ) {
    this.identify(user);
    this.segment.track(
      eventName,
      { ...properties, ...this.getCleanUserData(user) },
      { Mixpanel: true },
    );
  }

  public page(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    category: string,
    pageName: string,
    properties: Record<string, any>,
  ) {
    this.segment.page(
      category,
      pageName,
      { ...properties, ...this.getCleanUserData(user) },
      { Mixpanel: true },
    );
  }

  // Page events

  // pages/client
  public viewedLanding(user: Partial<ServerTypes.UserWithProfessions> | null) {
    const pageName = user ? 'Landing Page' : 'Landing Page (Anonymous)';
    const category = 'Landing Page';
    const properties = this.getCleanUserData(user);
    this.page(user, category, pageName, properties);
  }

  // pages/search/client
  public viewedChatpage(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    search: SearchCompanion | null,
    currRoute: string,
  ) {
    const sharedAnonymous = currRoute.includes('share=anonymous');
    const sharedUser = !sharedAnonymous && currRoute.includes('share=');
    const shareSuffix = sharedAnonymous
      ? '(Shared by Anonymous)'
      : sharedUser
        ? '(Shared by User)'
        : '';
    const pageName = `${user ? 'Chatpage' : 'Chatpage (Anonymous)'} ${shareSuffix}`;
    const category = 'Chatpage';
    const queryId = search?.question.getQueryId();
    const isSuggestedQuery = isDefaultQuery(queryId, currRoute);
    const properties = {
      ...this.getCleanUserData(user),
      question: search?.question.getText(),
      queryId: queryId,
      tags: search?.question.getTags(),
      isSuggestedQuery,
      sources: search?.question.getSources(),
      answer: search?.question.getAnswer(),
    };
    this.page(user, category, pageName, properties);
  }

  // pages/create-account
  public viewedSignupPage() {
    const pageName = 'Sign up Page (Anonymous)';
    const user = null;
    const category = 'Authentication';
    const properties = this.getCleanUserData(user);
    this.page(user, category, pageName, properties);
  }

  // pages/login
  public viewedSigninPage() {
    const pageName = 'Sign In Page (Anonymous)';
    const user = null;
    const category = 'Authentication';
    const properties = this.getCleanUserData(user);
    this.page(user, category, pageName, properties);
  }

  // pages/post-registration
  public viewedPostRegistration(
    user: Partial<ServerTypes.UserWithProfessions> | null,
  ) {
    const pageName = 'Post-Registration';
    const category = 'Authentication';
    const properties = this.getCleanUserData(user);
    this.page(user, category, pageName, properties);
  }

  // Track events

  // Home/unauthFooter
  public clickedFooterLinkUnauth(link: string, title: string) {
    const eventName = 'Clicked Footer Link';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      link,
      title,
    };
    this.track(user, eventName, properties);
  }

  // recommendedQs/desktop, recommendedQs/questionBox
  public clickedSuggestedQuery(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    queryId: string,
    question: string,
    tag: string,
  ) {
    const eventName = user
      ? 'Clicked Suggested Query'
      : 'Clicked Suggested Query (Anonymous)';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId,
      tag,
    };
    this.track(user, eventName, properties);
  }

  // Home/unauth
  public clickedHomeSearchboxUnauth() {
    const user = null;
    this.track(
      user,
      'Clicked Landing Searchbox (Anonymous)',
      this.getCleanUserData(user),
    );
  }

  // SearchBox
  public questionAsked(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    question: string | undefined,
  ) {
    const eventName = 'Question asked';
    const properties = {
      ...this.getCleanUserData(user),
      question,
    };
    this.track(user, eventName, properties);
  }

  // providers/question
  public questionAnswered(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    question: string,
    queryId: string,
    tags: string[],
    sources: SourceTitleWithState[],
    answer: string,
  ) {
    const eventName = 'Question asked & answered';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId,
      tags,
      sources,
      answer,
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public answerStopped(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    search: SearchCompanion | null,
  ) {
    const eventName = 'Stopped Generating Answer';
    const properties = {
      ...this.getCleanUserData(user),
      question: search?.question.getText(),
      queryId: search?.question.getQueryId(),
      tags: search?.question.getTags(),
      sources: search?.question.getSources(),
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public answerRegenerated(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    search: SearchCompanion | null,
  ) {
    const eventName = 'Regenerated Answer (Chatpage)';
    const properties = {
      ...this.getCleanUserData(user),
      question: search?.question.getText(),
      queryId: search?.question.getQueryId(),
      tags: search?.question.getTags(),
      sources: search?.question.getSources(),
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public answerRegeneratedSourceMgmt(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    search: SearchCompanion,
  ) {
    const eventName = 'Regenerated Answer (Source Management)';
    const properties = {
      ...this.getCleanUserData(user),
      question: search.question.getText(),
      queryId: search.question.getQueryId(),
      tags: search.question.getTags(),
      sources: search.question.getSources(),
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public openedSourceMgmt(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    search: SearchCompanion | null,
  ) {
    const eventName = 'Opened Source Management';
    const properties = {
      ...this.getCleanUserData(user),
      question: search?.question.getText(),
      queryId: search?.question.getQueryId(),
      tags: search?.question.getTags(),
      sources: search?.question.getSources(),
    };
    this.track(user, eventName, properties);
  }

  // providers/question
  public deletedSource(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    queryId: string,
    question: string,
    sourceTitle: string,
    sourceUrl: string,
  ) {
    const eventName = 'Deleted Source';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId,
      sourceTitle,
      sourceUrl,
    };
    this.track(user, eventName, properties);
  }

  // providers/question
  public reenabledSource(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    queryId: string,
    question: string,
    sourceTitle: string,
    sourceUrl: string,
  ) {
    const eventName = 'Re-Enabled Source';
    const properties = {
      ...this.getCleanUserData(user),
      question,
      queryId,
      sourceTitle,
      sourceUrl,
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public openedSource(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    search: SearchCompanion | null,
    sourceTitle: string,
    url: string,
    location: string,
  ) {
    const eventName = 'Re-Enabled Source';
    const properties = {
      ...this.getCleanUserData(user),
      question: search?.question.getText(),
      queryId: search?.question.getQueryId(),
      sourceTitle,
      url,
      location,
    };
    this.track(user, eventName, properties);
  }

  // SearchContent
  public shared(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    search: SearchCompanion | null,
  ) {
    const eventName = user
      ? 'Shared Question Link'
      : 'Shared Question Link (Anonymous)';
    const properties = {
      ...this.getCleanUserData(user),
      question: search?.question.getText(),
      queryId: search?.question.getQueryId(),
      tags: search?.question.getTags(),
      answer: search?.question.getAnswer(),
    };
    this.track(user, eventName, properties);
  }

  // Sidebar/tagContainer
  public clickedPreviousSearch(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    prevSearch: SearchLabel,
  ) {
    const eventName = 'Clicked Previous Search';
    const properties = {
      ...this.getCleanUserData(user),
      title: prevSearch.title,
      queryId: prevSearch.queryId,
    };
    this.track(user, eventName, properties);
  }

  // ChatBox/auth
  public clickedDiscover(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    question: string,
  ) {
    const eventName = 'Clicked Discovery Button';
    const properties = {
      ...this.getCleanUserData(user),
      question,
    };
    this.track(user, eventName, properties);
  }

  // AccountSettingsModal
  public signedOut(user: Partial<ServerTypes.UserWithProfessions> | null) {
    const eventName = 'Signed Out';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // providers/authProvider
  public signedInEmail(user: Partial<ServerTypes.UserWithProfessions> | null) {
    const eventName = 'Signed In (Email)';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // providers/authProvider
  public signedInGoogle(user: Partial<ServerTypes.UserWithProfessions>) {
    const eventName = 'Signed In (Google OAuth)';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/login
  public incorrectPassword(email: string) {
    const eventName = 'Incorrect Password on Sign in';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      email,
    };
    this.track(user, eventName, properties);
  }

  // pages/createAccount
  public preverified(email: string) {
    const eventName = 'Signup - Entered Email and Password (Preverified)';
    const user = null;
    const properties = {
      email,
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // providers/authProvider
  public signedupEmail(user: Partial<ServerTypes.UserWithProfessions>) {
    const eventName = 'Sign Up (Email) Success';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // providers/authProvider
  public signedupGoogle(user: Partial<ServerTypes.UserWithProfessions>) {
    const eventName = 'Sign Up (Google OAuth) Success';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/verify
  public enteredCodeFailure(email: string) {
    const eventName = 'Entered Wrong Verification Code';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      email,
    };
    this.track(user, eventName, properties);
  }

  // pages/verify
  public resendCodeEmail(email: string) {
    const eventName = 'Resent Verification Code';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      email,
    };
    this.track(user, eventName, properties);
  }

  // pages/login
  public clickedForgotPassword(email: string) {
    const eventName = 'Clicked Forgot Password';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      email,
    };
    this.track(user, eventName, properties);
  }

  // pages/password-reset
  public resetPassword(email: string) {
    const eventName = 'Reset Password Success';
    const user = null;
    const properties = {
      ...this.getCleanUserData(user),
      email,
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public addedName(user: Partial<ServerTypes.UserWithProfessions> | null) {
    const eventName = 'Added First Name & Last Name';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public addedProfession(
    user: Partial<ServerTypes.UserWithProfessions> | null,
  ) {
    const eventName = 'Added Profession';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public skippedProfession(
    user: Partial<ServerTypes.UserWithProfessions> | null,
  ) {
    const eventName = 'Skipped Profession';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public invitedUser(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    inviteeEmails: string[],
  ) {
    const eventName = 'Invited Emails';
    const properties = {
      ...this.getCleanUserData(user),
      inviteeEmails,
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public skippedInviteUser(
    user: Partial<ServerTypes.UserWithProfessions> | null,
  ) {
    const eventName = 'Skipped Invited Emails';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // pages/post-registration
  public completedOnboarding(
    user: Partial<ServerTypes.UserWithProfessions> | null,
  ) {
    const eventName = 'Onboarding success';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  // Sidebar
  public sharedInviteLink(
    user: Partial<ServerTypes.UserWithProfessions> | null,
  ) {
    const eventName = 'Clicked copy/share invite link';
    const properties = {
      ...this.getCleanUserData(user),
    };
    this.track(user, eventName, properties);
  }

  public generic(
    user: Partial<ServerTypes.UserWithProfessions> | null,
    eventName: string,
    properties?: Record<string, any>,
  ) {
    this.track(user, eventName, {
      ...this.getCleanUserData(user),
      ...properties,
    });
  }
}

if (!process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
  throw new Error(`NEXT_PUBLIC_SEGMENT_WRITE_KEY not set.`);
}

export const analytics = new Analytics(
  process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
);
