export interface Q {
  question: string;
  searchId: string;
}
export interface TagQs {
  tag: string;
  questions: Q[];
}

export const defaultRecommendedDev: TagQs[] = [
  {
    tag: '#المناخ',
    questions: [
      {
        question:
          'كيف يؤثر الطقس في الإمارات العربية المتحدة على بنيتها التحتية؟',
        searchId: '3f5783a5-d607-43f9-bbbe-01c05376911b',
      },
      {
        question:
          'ما هي آخر التطورات في قطاع الطاقة المتجددة في الإمارات العربية المتحدة؟',
        searchId: 'bc1963aa-91c5-4368-841e-2bb304e68761',
      },
    ],
  },
  {
    tag: '#الاقتصاد',
    questions: [
      {
        question:
          'ما هي الخطوات التي تتخذها الإمارات العربية المتحدة نحو التنويع الاقتصادي؟',
        searchId: 'f6d32d88-d921-458d-b778-e86f302f8d19',
      },
      {
        question:
          'ما هو الدور الذي تلعبه القوى العاملة الوافدة في اقتصاد الإمارات العربية المتحدة؟',
        searchId: 'f9a47fa0-676e-47be-8483-928b6670e8b7',
      },
    ],
  },
  {
    tag: '#السياسة الخارجية',
    questions: [
      {
        question:
          'كيف تؤثر السياسة الخارجية لدولة الإمارات العربية المتحدة على علاقاتها الدولية؟',
        searchId: '989a8630-be7a-4f84-bb2f-e550f0059913',
      },
      {
        question:
          'كيف تستقطب المؤسسات التعليمية في الإمارات العربية المتحدة الطلاب الدوليين؟',
        searchId: '6e21b190-16f1-4353-ae60-a267cc75e57e',
      },
    ],
  },
];

export const defaultRecommendedProd: TagQs[] = [
  {
    tag: '#المناخ',
    questions: [
      {
        question:
          'كيف يؤثر الطقس في الإمارات العربية المتحدة على بنيتها التحتية؟',
        searchId: 'ca07785d-29e0-45f3-8a0f-4b67c7bc8bef',
      },
      {
        question:
          'ما هي آخر التطورات في قطاع الطاقة المتجددة في الإمارات العربية المتحدة؟',
          searchId: '6336cf4c-dcf0-4f9f-94ff-ba72fa8ff590',
      },
    ],
  },
  {
    tag: '#الاقتصاد',
    questions: [
      {
        question:
          'ما هي الخطوات التي تتخذها الإمارات العربية المتحدة نحو التنويع الاقتصادي؟',
        searchId: '086c6b63-dfda-4a0c-9b21-a41774b5a4e9',
      },
      {
        question:
          'ما هو الدور الذي تلعبه القوى العاملة الوافدة في اقتصاد الإمارات العربية المتحدة؟',
        searchId: '9c707664-2604-4cc8-b013-6d716bfb6a9a',
      },
    ],
  },
  {
    tag: '#السياسة الخارجية',
    questions: [
      {
        question:
          'كيف تؤثر السياسة الخارجية لدولة الإمارات العربية المتحدة على علاقاتها الدولية؟',
        searchId: '0348a516-addc-4e85-8ed8-50b4610b4e1b',
      },
      {
        question:
          'ما هي الخطط الموضوعة لزيادة التجارة الثنائية في دولة الإمارات العربية المتحدة؟',
        searchId: '2512d33b-5856-4b3f-bf2c-97049761f39b',
      },
    ],
  },
];
